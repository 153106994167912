
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.Section {
  position: relative;
  padding-top: spacing(2);
  padding-bottom: spacing(2);
  color: $color-coolgrey-l25;
}

.backgroundLight {
  background: $color-white;
}

.borderBottom {
  border-bottom: 1px solid $color-blue;
}

.padded {
  padding-top: spacing(3);
  padding-bottom: spacing(3);

  @include mq($breakpoint-tablet) {
    padding-top: spacing(5);
    padding-bottom: spacing(5);
  }
}

.shadowBottom,
.shadowTop {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: spacing(1);
    pointer-events: none;
  }
}

.shadowBottom {
  &::after {
    top: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba($color-coolgrey-l25, 0.1),
      rgba($color-coolgrey-l25, 0)
    );
  }
}

.shadowTop {
  &::after {
    bottom: 100%;
    background-image: linear-gradient(
      to top,
      rgba($color-coolgrey-l25, 0.1),
      rgba($color-coolgrey-l25, 0)
    );
  }
}

.minHeight {
  min-height: spacing(60);
}
