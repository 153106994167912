
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
$header-switch-tiny: $breakpoint-mobileLandscape;
$header-switch-small: $breakpoint-tablet + 100;
$header-switch-medium: $breakpoint-desktop;
$header-switch-large: $breakpoint-desktop + 200;

.HeaderBar {
  background-color: $color-blue;
  color: $color-white;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;

  @include mq($header-switch-small) {
    flex-wrap: nowrap;
  }
}

.HeaderBarLogo {
  padding: spacing(2) spacing(2);
  flex: 1 0 auto;
  @include mq($header-switch-small) {
    padding: spacing(2) spacing(3);
    flex: 0 0 auto;
  }
}

.HeaderBarMobileToggle {
  order: -1;
  border-right: 1px solid $color-white-op70;
  @include mq($header-switch-small) {
    display: none !important;
  }
}

.HeaderBarNav {
  order: 1;
  flex: 1 0 100%;
  border-top: 1px solid $color-white-op70;
  display: none;

  &.open {
    display: block;
  }

  @include mq($header-switch-small) {
    display: block;
    order: 0;
    flex: 1 0 auto;
    border-top: 0;
    border-left: 1px solid $color-white-op70;
  }

  .HeaderBarNavLink {
    > span {
      @include mq($min: $header-switch-small, $max: $header-switch-medium) {
        display: none;
      }
    }
  }
}

.HeaderBarNavList {
  height: 100%;
  display: flex;
  align-items: center;
}

.HeaderBarNavItem {
  list-style: none;

  @include mq($header-switch-small) {
    display: inline-block;
  }
}

.HeaderBarNavLink {
  display: block;
  padding: spacing(2);
  color: $color-white;
  text-decoration: none;
  opacity: 0.7;
  transition: opacity $duration-standard $easing-standard;
  font-size: 14px;
  font-weight: bold;
  @include font-smoothing();

  &:hover,
  &:focus,
  &.active {
    opacity: 1;
    transition-duration: $duration-shortest;
  }
}

//
// USER NAV
//

.HeaderBarUserNavToggle {
  border-left: 1px solid $color-white-op70;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 1;
}

.HeaderBarUserNav {
  flex: 1 0 100%;
  display: none;
  order: 1;

  &.open {
    display: block;
  }

  @include mq($header-switch-small) {
    flex: 0 0 auto;
    position: absolute;
    top: 100%;
    right: 0;
  }
}

.HeaderBarUserNavName {
  line-height: 1;
  padding-left: spacing(1);
  padding-right: spacing(1);
  display: none;
  white-space: nowrap;
  opacity: 0.6;
  transition: opacity $duration-standard $easing-standard;

  @include mq($header-switch-large) {
    display: inline-block;
  }

  .HeaderBarUserNavToggle:hover &,
  .HeaderBarUserNavToggle:focus & {
    opacity: 1;
    transition-duration: $duration-shortest;
  }
}

.HeaderBarUserNavCarat {
  display: none;
  @include mq($header-switch-tiny) {
    display: block;
  }
}

.HeaderBarUserNavList {
  background-color: $color-blue;
  color: $color-white;
  border: 1px solid $color-white-op70;
  border-right-width: 0;
  padding-top: spacing(1);
  padding-bottom: spacing(1);
}

.HeaderBarUserNavItem {
  list-style: none;
}

.HeaderBarUserNavLink {
  padding-top: spacing(1);
  padding-bottom: spacing(1);
}

.HeaderBarLogin {
  padding-right: spacing(2);
  display: flex;
  align-items: center;
}
