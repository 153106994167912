
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
$align-types: center, justify, left, right;

@each $alignment in $align-types {
  .#{$alignment} {
    text-align: $alignment;
  }
}
