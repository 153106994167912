
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.ButtonStandard {
  position: relative;
  border-radius: $radius-small;
  background-color: $color-green;
  color: $color-white;
  padding-left: spacing(3);
  padding-right: spacing(3);
  text-align: center;
  font-family: $font-heading;
  font-weight: 600;
  text-decoration: none;
  transition: all $duration-standard $easing-standard;
  min-width: spacing(20);
  @include font-smoothing();

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px $color-white inset, 0 0 0 4px rgba($color-green, 0.3);
  }
}

.ButtonStandardInner {
  min-height: spacing(3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ButtonStandardContent {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  transition: opacity $duration-standard $easing-standard;

  // Push text slightly higher for visual balance
  position: relative;
  top: -1px;

  svg {
    position: relative;
    top: 1px;
  }

  > * + * {
    margin-left: spacing(1);
  }
}

.ButtonStandardSpinner {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  pointer-events: none;

  .ButtonStandardContent {
    transition-duration: $duration-shortest;
    opacity: 0;
  }

  .ButtonStandardSpinner {
    opacity: 1;
  }
}

.alt {
  background-color: $color-blue;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px $color-white inset, 0 0 0 4px rgba($color-blue, 0.3);
  }

  svg {
    fill: currentColor;
  }
}

.muted {
  background-color: $color-coolgrey-l98;
  color: $color-coolgrey-l25;
  border: 1px solid $color-coolgrey-l80;

  &:hover,
  &:focus,
  &:active {
    background-color: $color-coolgrey-l98;
    border-color: $color-blue;
    color: $color-coolgrey-l25;
    box-shadow: 0 0 0 1px $color-white inset,
      0 0 0 4px rgba($color-coolgrey-l80, 0.3);
  }

  svg {
    fill: currentColor;
  }
}

.disabled {
  background-color: $color-coolgrey-l80;
  cursor: not-allowed;
  box-shadow: none !important;

  svg {
    fill: currentColor;
  }
}

.visuallyDisabled {
  background-color: $color-coolgrey-l80;
  box-shadow: none !important;

  svg {
    fill: currentColor;
  }
}

.shrink {
  min-width: 0;
}

.outline {
  background-color: transparent;
  border: 1px solid $color-green;
  color: $color-green;
  padding-top: spacing(1.25) - 1;
  padding-bottom: spacing(1.25) - 1;

  &:hover,
  &:focus,
  &:active {
    color: $color-green;
  }

  &.alt {
    border-color: $color-blue;
    color: $color-blue;

    // &:hover,
    // &:focus,
    // &:active {
    //   color: $color-yellow;
    // }
  }

  &.muted {
    border-color: $color-coolgrey-l80;
    color: $color-coolgrey-l25;
    background-color: transparent;
  }

  &.inverse {
    border-color: $color-white;
    color: $color-white;

    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 1px $color-white inset,
        0 0 0 4px rgba($color-white, 0.3);
    }
  }

  &.disabled {
    color: $color-coolgrey-l70;
    border-color: $color-coolgrey-l70;
  }
}

// 32px (spacing * 4)
.size-xs {
  font-size: spacing(1.75);
  min-width: 0;
  padding-top: spacing(0.5);
  padding-bottom: spacing(0.5);
  padding-left: spacing(2);
  padding-right: spacing(2);
  &.bordered {
    padding-top: spacing(0.5) - 1;
    padding-bottom: spacing(0.5) - 1;
    padding-left: spacing(2) - 1;
    padding-right: spacing(2) - 1;
  }
  &.tight {
    padding-left: spacing(0.5);
    padding-right: spacing(0.5);
  }
}

// 36px (spacing * 4.5)
.size-s {
  font-size: spacing(1.75);
  min-width: spacing(18);
  padding-top: spacing(0.75);
  padding-bottom: spacing(0.75);
  &.bordered {
    padding-top: spacing(0.75) - 1;
    padding-bottom: spacing(0.75) - 1;
  }
  &.tight {
    padding-left: spacing(0.75);
    padding-right: spacing(0.75);
  }
}

// 40px (spacing * 5)
.size-m {
  font-size: spacing(2);
  padding-top: spacing(1);
  padding-bottom: spacing(1);
  &.bordered {
    padding-top: spacing(1) - 1;
    padding-bottom: spacing(1) - 1;
  }
  &.tight {
    padding-left: spacing(1);
    padding-right: spacing(1);
  }
}

// 48px (spacing * 6)
.size-l {
  font-size: spacing(2);
  padding-top: spacing(1.5);
  padding-bottom: spacing(1.5);
  &.bordered {
    padding-top: spacing(1.5) - 1;
    padding-bottom: spacing(1.5) - 1;
  }
  &.tight {
    padding-left: spacing(1.5);
    padding-right: spacing(1.5);
  }
}

// 56px (spacing * 7)
.size-xl {
  font-size: spacing(2);
  padding-top: spacing(2);
  padding-bottom: spacing(2);
  &.bordered {
    padding-top: spacing(2) - 1;
    padding-bottom: spacing(2) - 1;
  }
  &.tight {
    padding-left: spacing(2);
    padding-right: spacing(2);
  }
}
