
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.SimplePage {
  background-color: $color-coolgrey-l96;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.SimplePageMain {
  @include mq($breakpoint-tablet) {
    flex-grow: 1;
  }
}
