
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.Container {
  width: 100%;
  clear: both;
}

.noClearfix {
  clear: none;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.gutter {
  padding-left: spacing(2);
  padding-right: spacing(2);

  @include mq($breakpoint-tablet) {
    padding-left: spacing(4);
    padding-right: spacing(4);
  }
}

.gutterNarrow {
  padding-left: spacing(2);
  padding-right: spacing(2);
}

.gutterWide {
  padding-left: spacing(2);
  padding-right: spacing(2);

  @include mq($breakpoint-tablet) {
    padding-left: spacing(4);
    padding-right: spacing(4);
  }

  @include mq($breakpoint-desktop) {
    padding-left: spacing(8);
    padding-right: spacing(8);
  }
}

// SIZES

.size-xxl {
  max-width: spacing(140);
}

.size-xl {
  max-width: spacing(110);
}

.size-l {
  max-width: spacing(92);
}

.size-m {
  max-width: spacing(80);
}

.size-s {
  max-width: spacing(60);
}

.size-full {
  max-width: none;
}
