
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.PageSummary {
  display: flex;
  min-width: 0;
  align-items: center;
  border-bottom: 1px solid $color-coolgrey-l80;
}

.PageSummaryContent {
  flex: 1 1 auto;
  padding-bottom: spacing(3) - 1;

  > * + * {
    margin-top: spacing(1);
  }
}

.PageSummaryImage {
  min-width: 128px;
  margin-top: spacing(-1);
  display: none;
  // transform: translateY(spacing(-1));

  @include mq($breakpoint-tablet) {
    display: block;
  }
}

.PageSummaryAction {
  margin-bottom: spacing(2.5);
}
