
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.IconButton {
  vertical-align: middle;
  display: inline-block;
  height: spacing(6);
  min-width: spacing(6);
  white-space: nowrap;

  &:focus,
  &:hover {
    text-decoration: none;
    outline: 0; // TODO: something better here
  }

  &:visited {
    color: inherit;
  }
}

.IconButtonInner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    vertical-align: top;
  }
}

.IconButtonText {
  margin-left: spacing(0.5);
}

.rounded {
  border-radius: 50%;
}

.primary {
  color: $color-blue;
}

.solid {
  color: $color-white;
  background-color: $color-blue;
  transition: all $duration-standard $easing-standard;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px $color-white inset, 0 0 0 4px rgba($color-blue, 0.3);

    &.rounded {
      box-shadow: 0 0 0 1.5px $color-white inset,
        0 0 0 4px rgba($color-blue, 0.3);
    }
  }
}

.light {
  color: $color-coolgrey-l50;
}

.muted {
  color: $color-coolgrey-l70;
  transition: color $duration-standard $easing-standard;
  &:focus,
  &:hover,
  &:active {
    transition-duration: $duration-short;
    color: $color-blue;
  }
}

.size-s {
  height: spacing(3);
  min-width: spacing(3);
  vertical-align: bottom;
}

.size-l {
  height: spacing(8);
  min-width: spacing(8);
}

.increaseHitArea {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: spacing(-2);
    right: spacing(-2);
    bottom: spacing(-2);
    left: spacing(-2);
  }
}
