
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.FooterContact {
  background-color: $color-coolgrey-l17;
  color: $color-white;
  padding-top: spacing(2);
  padding-bottom: spacing(2);
  @include font-smoothing();
}

.FooterContactInner {
  @include mq($breakpoint-desktop) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.FooterContactLinkListItem {
  @include mq($breakpoint-tablet) {
    display: inline-block;
  }
}

.FooterContactLink {
  display: flex;
  align-items: center;
  color: $color-white;
  padding: spacing(1);
  text-decoration: none;
}

.FooterContactLinkIcon {
  opacity: 0.5;
  margin-right: spacing(0.5);
  transition: all $duration-standard $easing-standard;
  color: $color-electric-l75;

  .FooterContactLink:hover &,
  .FooterContactLink:focus & {
    opacity: 1;
    color: $color-white;
    transition-duration: $duration-shortest;
  }
}

.FooterContactLinkDisplay {
  display: inline-block;
  transition: color $duration-standard $easing-standard;
  color: $color-electric-l75;

  .FooterContactLink:hover &,
  .FooterContactLink:focus & {
    text-decoration: underline;
    color: $color-white;
    transition-duration: $duration-shortest;
  }

  .highlight & {
    font-weight: bold;
    text-decoration: underline;
  }
}

.FooterContactSocialLink {
  display: block;
  color: $color-electric-l75;
  padding: spacing(1);
  transition: color $duration-standard $easing-standard;

  &:hover,
  &:focus {
    color: $color-white;
    transition-duration: $duration-shortest;
  }
}
