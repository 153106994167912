
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.LogoLockup {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  a {
    display: block;
    color: $color-white;
  }
}

.LogoLockupStrapline {
  color: $color-white-op70;
  line-height: spacing(4);
  font-family: $font-heading;
  white-space: nowrap;
  font-size: spacing(2);
  margin-left: spacing(1.5);
  padding-left: spacing(1.5);
  border-left: 1px solid;
  @include font-smoothing();
}

.mobileMinimal {
  @include mq($max: $breakpoint-tablet - 1) {
    .LogoLockupStrapline {
      display: none;
    }
  }
}

.tabletMinimal {
  @include mq($max: $breakpoint-desktop - 1) {
    .LogoLockupStrapline {
      display: none;
    }
  }
}

.mobileStack {
  @include mq($max: $breakpoint-tablet - 1) {
    flex-direction: column;

    .LogoLockupStrapline {
      margin-left: 0;
      padding-left: 0;
      border-left: 0;
    }
  }
}

.small {
  .LogoLockupStrapline {
    font-size: spacing(1.75);
    line-height: spacing(2.5);
    font-weight: 700;
  }
}
