
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.FooterLegal {
  background-color: $color-white;
  border-top: spacing(0.5) solid $color-lightblue-l50;
  padding-top: spacing(3);
  padding-bottom: spacing(3);
}

.FooterLegalInner {
  @include mq($breakpoint-desktop) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.FooterLegalSmall {
  display: block;
  margin-bottom: spacing(2);

  @include mq($breakpoint-desktop) {
    flex-basis: 66%;
    margin-bottom: 0;
  }
}
