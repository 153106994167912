
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.CustomCheckControl {
  display: inline-block;
  position: relative;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.CustomCheckControlIndicator {
  position: relative;
  display: inline-block;
  width: spacing(3);
  height: spacing(3);
  background: $color-white;
  vertical-align: bottom;
  border: 1px solid $color-coolgrey-l70;
  border-radius: $radius-small;
  transition: all $duration-standard $easing-standard;

  &::after {
    position: absolute;
    content: '';
    opacity: 0;
    transform: scale(0);
    transition: opacity $duration-long $easing-standard,
      transform $duration-long $easing-bounce;
  }

  input:checked ~ & {
    &::after {
      opacity: 1;
      transform: scale(1);
      transition-duration: $duration-shortest;
    }
  }

  input:hover:not([disabled]) ~ &,
  input:focus ~ & {
    border-color: $color-coolgrey-l60;
    transition-duration: $duration-shortest;
  }

  input:active ~ &,
  input:focus ~ & {
    border-color: $color-blue;
    box-shadow: 0 0 0 4px rgba($color-blue, 0.3);
  }

  input:hover:not([disabled]):checked ~ &,
  input:checked:focus ~ & {
    border-color: $color-blue;
  }

  input:disabled ~ & {
    opacity: 0.6;
    border-color: $color-coolgrey-l70;
    cursor: not-allowed;
  }
}

//
// CHECKBOX
//

.checkbox {
  .CustomCheckControlIndicator::after {
    top: 50%;
    left: 50%;
    margin-left: -4px;
    margin-top: -8px;
    width: 7px;
    height: 14px;
    transform: scale(0) rotate(45deg);
    border-style: solid;
    border-color: $color-white;
    border-width: 0 3px 3px 0;
  }
  input:checked ~ .CustomCheckControlIndicator {
    background-color: $color-blue;
    border-color: transparent;
  }

  input:checked ~ .CustomCheckControlIndicator::after {
    transform: scale(1) rotate(45deg);
  }
  input:disabled ~ .CustomCheckControlIndicator::after {
    border-color: $color-coolgrey-l70;
  }
}

//
// RADIO
//

.radio {
  .CustomCheckControlIndicator {
    border-radius: 50%;
  }
  .CustomCheckControlIndicator::after {
    top: 50%;
    left: 50%;
    width: spacing(2);
    height: spacing(2);
    margin-left: spacing(-1);
    margin-top: spacing(-1);
    border-radius: 50%;
    background-color: $color-blue;
  }
  input:checked ~ .CustomCheckControlIndicator {
    border-color: $color-blue;
  }
  input:disabled .CustomCheckControlIndicator::after {
    background-color: $color-coolgrey-l70;
  }
}

@each $key, $value in $color-status-list {
  .#{$key} {
    .CustomCheckControlIndicator {
      border-color: $value;
    }
    input:hover:not([disabled]) ~ .CustomCheckControlIndicator,
    input:focus ~ .CustomCheckControlIndicator {
      border-color: $value;
    }
    input:checked ~ .CustomCheckControlIndicator {
      border-color: $value;
    }
    input:hover:not([disabled]):checked ~ .CustomCheckControlIndicator,
    input:checked:focus ~ .CustomCheckControlIndicator {
      border-color: $value;
      box-shadow: 0 0 0 1px $value inset;
    }
  }
  .checkbox.#{$key} {
    .CustomCheckControlIndicator::after {
      border-color: $value;
    }
  }
  .radio.#{$key} {
    .CustomCheckControlIndicator::after {
      background-color: $value;
    }
  }
}
