
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.TextControl {
  display: block;
  width: 100%;
  font-family: inherit;
  font-size: 16px; // Any smaller enables iOS zooming on focus
  background-color: $color-white;
  transition: all $duration-standard $easing-standard;
  padding: spacing(1.5) - 1 spacing(2) - 1;
  color: inherit;
  line-height: spacing(3);
  border: 1px solid $color-coolgrey-l70;
  background-clip: padding-box;
  border-radius: $radius-small;
  caret-color: $color-blue;

  // &::placeholder {}

  &:hover,
  &:focus {
    transition-duration: $duration-shortest;
  }

  &:hover {
    border-color: $color-coolgrey-l60;
  }

  &:focus {
    border-color: $color-blue;
    box-shadow: 0 0 0 4px rgba($color-blue, 0.3),
      0 0 0 rgba($color-coolgrey-l90, 0.5) inset;
    outline: 0;
  }

  &[type='date'] {
    appearance: none;
    height: spacing(6);
  }
}

.multiLine {
  height: auto;
  resize: vertical;
}

@each $key, $value in $color-status-list {
  .#{$key} {
    border-color: $value !important;
  }
}

.disabled {
  pointer-events: none;
  color: $color-coolgrey-l60 !important;
  border-color: $color-coolgrey-l90 !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
  }
}
