
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.FieldQuestion {
  margin-bottom: spacing(2);
  display: inline-block;
  font-weight: 600;
  color: $color-coolgrey-l25;
}

.disabled {
  color: $color-coolgrey-l60;
}
