
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.ButtonBase {
  display: inline-block;
  vertical-align: middle;

  &:focus,
  &:hover {
    outline: 0;
  }

  // &:active {}
}

.disabled {
  user-select: none;
  pointer-events: none;
  cursor: not-allowed;
}

.block {
  display: block;
  width: 100%;
  min-width: 0 !important;
}
