
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
$HighlightBanner-medium: $breakpoint-tablet;
$HighlightBanner-large: $breakpoint-desktop + 100;

.HighlightsBanner {
  background-image: linear-gradient(
    150deg,
    $color-lightblue-l50 10%,
    $color-blue
  );
  color: $color-white;
  font-weight: 700;
  text-transform: uppercase;

  @include mq($HighlightBanner-medium) {
    display: flex;
  }

  @include mq($HighlightBanner-large) {
    background-image: linear-gradient(
      175deg,
      $color-lightblue-l50 -10%,
      $color-blue
    );
  }
}

.HighlightsBannerHeader {
  background: $color-lightblue-l50;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(2) spacing(4);
  text-align: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: spacing(2.5) solid transparent;
    border-bottom-width: 0;
    border-top-color: $color-lightblue-l50;

    @include mq($HighlightBanner-medium) {
      border: spacing(2.5) solid transparent;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      border-right-width: 0;
      border-left-color: $color-lightblue-l50;
    }
  }
}

.HighlightsBannerHeaderInner {
  > * + * {
    margin-top: spacing(1);
    color: $color-white-op70;
  }
}

.HighlightsBannerList {
  list-style: none;
  padding: spacing(4) spacing(0) spacing(2);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-grow: 1;

  @include mq($HighlightBanner-medium) {
    padding: spacing(2) spacing(2) spacing(2) spacing(4);
  }
}

.HighlightsBannerListItem {
  flex-basis: 30%;
  text-align: center;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  padding: spacing(1);
  @include font-smoothing();

  a {
    color: $color-white;
    text-decoration: none;
  }

  @include mq($HighlightBanner-medium) {
    letter-spacing: 1px;
    font-size: 13px;
    line-height: 20px;
  }

  @include mq($HighlightBanner-large) {
    flex-basis: math.div(100%, 6);
  }
}

.HighlightsBannerIcon {
  width: spacing(7);
  height: spacing(7);
  border: 2px solid $color-lightblue-l65;
  box-shadow: 0 3px 11px rgba($color-blue-l30, 0.35) inset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: spacing(1);
}
