// Based on typographic scale of 13, 14, 16, 20, 24, 34, 48

// font-size and line-height combos
// Remember scss `list` indexes start at 1, not 0
$type-scale: (
  13px: math.div(16, 13),
  14px: math.div(20, 14),
  16px: math.div(24, 16),
  20px: math.div(24, 20),
  24px: math.div(32, 24),
  34px: math.div(48, 34),
  48px: math.div(64, 48)
);

@mixin type-scale($size) {
  $sizePair: nth($type-scale, $size);

  @if length($sizePair) > 0 {
    font-size: nth($sizePair, 1);
    line-height: nth($sizePair, 2);
  }
}
