
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.FieldFeedback {
  margin-top: spacing(0.5);
  color: $color-coolgrey-l50;
  @include type-scale(2);
}

@each $key, $value in $color-status-list {
  .#{$key} {
    color: $value !important;
  }
}
