
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.ReviewCard {
  text-align: center;
  box-shadow: 0 spacing(8) spacing(8) spacing(-6) rgba($color-coolgrey-l25, 0.1);
  border-radius: $radius-standard;
  background-color: $color-white;
  padding: spacing(4) spacing(2);
  // max-width: 280px;

  @include mq($breakpoint-tablet) {
    // max-width: 320px;
  }

  @include mq($breakpoint-desktop) {
    // max-width: 360px;
    padding: spacing(6) spacing(4);
  }

  cite {
    display: inline-block;
    font-style: normal;
    text-transform: uppercase;
  }
}

.ReviewCardRating {
  display: flex;
  justify-content: center;
}
