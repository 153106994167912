
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.Type {
  a {
    color: inherit;
  }
}

// SIZES
.size-xs {
  @include type-scale(1);
}
.size-s {
  @include type-scale(2);
}
.size-m {
  @include type-scale(3);
  // @include mq($breakpoint-desktop) {
  //   @include type-scale(3);
  // }
}
.size-l {
  @include type-scale(3);
  @include mq($breakpoint-desktop) {
    @include type-scale(4);
  }
}
.size-xl {
  @include type-scale(4);
  @include mq($breakpoint-desktop) {
    @include type-scale(5);
  }
}
.size-xxl {
  @include type-scale(5);
  @include mq($breakpoint-desktop) {
    @include type-scale(6);
  }
}

// COLORS
.color-blue {
  color: $color-blue;
}
.color-lightblue-l50 {
  color: $color-lightblue-l50;
}
.color-coolgrey-l50 {
  color: $color-coolgrey-l50;
}
.color-coolgrey-l25 {
  color: $color-coolgrey-l25;
}
.color-coolgrey-l17 {
  color: $color-coolgrey-l17;
}
.color-white {
  color: $color-white;
}

// MODIFIERS

.normal {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}

.tight {
  line-height: 1;
}
