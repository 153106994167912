
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.PaginationButton {
  width: spacing(6);
  height: spacing(6);
  border: 2px solid;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-blue;
  border-radius: 50%;
  transition: all $duration-standard $easing-standard;

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus-visible {
    box-shadow: 0 0 0 4px rgba($color-blue, 0.3);
  }

  &[disabled] {
    color: $color-coolgrey-l90;
    pointer-events: none;
    box-shadow: none;
  }
}

.inverse {
  color: $color-white;

  &:hover,
  &:focus-visible {
    box-shadow: 0 0 0 4px rgba($color-white, 0.3);
  }

  &[disabled] {
    color: $color-coolgrey-l50;
  }
}
