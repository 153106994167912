
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.ResponsiveMedia {
  position: relative;
  height: 0;
  overflow: hidden;
  width: 100%; // Maintain size when centered using flexbox

  // Ensure content fill placeholder
  & > *,
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
  }
}

.placeholder {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(
    to right,
    $color-coolgrey-l98 8%,
    $color-grey-l95 38%,
    $color-coolgrey-l98 54%
  );
  background-size: 1000px 640px;

  position: relative;

  > * {
    display: none;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
