:global {
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }

  .swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    // height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0, 0, 0);
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    // height: 100%;
    position: relative;
    transition-property: transform;
  }

  // IE11 hack to add a new stacking context, to avoid next/prev slides breaking
  // out of the overflow-hidden container.
  @media all and (-ms-high-contrast: none) {
    .swiper-wrapper {
      opacity: 0.999;
    }
  }
}
