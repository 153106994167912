
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.ReviewCards {
  @import './vendor/swiper.scss'; /* stylelint-disable-line */

  // Allow swiper to overflow to let shadows show, but add overflow to top-level
  overflow: hidden;
  :global(.swiper-container) {
    overflow: visible;
  }
}

.ReviewCardsHeader {
  text-align: center;
  padding: spacing(2) 0;
  position: relative;
}

.ReviewCardsNav {
  position: absolute;
  top: spacing(1);
  right: 0;
  display: none;

  > * + * {
    margin-left: spacing(2);
  }

  @include mq($breakpoint-tablet) {
    display: flex;
  }
}

.ReviewCardsCarousel {
  user-select: none;
}

.ReviewCardsFooter {
  display: flex;
  justify-content: center;
  padding-left: spacing(4);
  padding-right: spacing(4);
  padding-bottom: spacing(4);
}

.ReviewCardsFooterLink {
  padding: 0 spacing(1);
  @include mq($breakpoint-tablet) {
    padding: spacing(1) spacing(2);
  }
}
