
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.Avatar {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-color: rgba($color-white, 60%);
  color: $color-blue;
}

.AvatarInitials {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  font-size: inherit;
  transform: translateY(-2%);
}
