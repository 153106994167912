
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
// .TextFieldAltTemplate {}

.TextFieldAltTemplateHeader {
  margin-bottom: spacing(2);
}

.TextFieldAltTemplateHeaderInner {
  display: inline-block;
  position: relative;
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  > *:first-child {
    display: inline;
  }
}

.TextFieldAltTemplateButton {
  display: inline-block;
  margin-left: spacing(0.5);
  transform: translateY(1px);

  .assistanceVisible & {
    display: none;
  }

  svg {
    fill: $color-coolgrey-l60 !important;
  }
}

.TextFieldAltTemplateAssistance {
  margin-top: spacing(0.5);
  > * {
    margin-top: 0;
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: spacing(2);
  }

  .assistanceHidden & {
    position: absolute !important;
    height: 1px !important;
    width: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    border: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }
}
