
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.FieldRequired {
  color: $color-coolgrey-l50;

  &[title] {
    text-decoration: none;
  }
}
