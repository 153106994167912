
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
$gap-list: (
  'xs': 1,
  's': 2,
  'm': 3,
  'l': 4
);

// .Stack {}

@each $key, $value in $gap-list {
  .#{$key} > * + * {
    margin-top: spacing($value);
  }
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   gaps: map-keys($gap-list);
// }
