
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.RatingItem {
  display: inline-block;

  svg + svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.isFull {
  fill: gold;
}

.isEmpty {
  fill: lightgrey;
}
