
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.Rule {
  height: 1px;
  border: 0;
  background-color: $color-blue;
  margin-bottom: -1px;
}

.muted {
  background-color: $color-coolgrey-l80;
}
