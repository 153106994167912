
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.Notification {
  color: $color-coolgrey-l25;
  padding: spacing(1.5) spacing(2);
  background-color: $color-coolgrey-l90;
  border-left: $radius-small solid $color-coolgrey-l60;
  border-radius: $radius-small;
  display: flex;

  svg {
    fill: $color-coolgrey-l60;
  }
}

.NotificationIcon {
  margin-right: spacing(1);

  @include mq($breakpoint-tablet) {
    margin-right: spacing(2);
  }
}

.NotificationContent {
  flex: 1 1 auto;
  min-width: 0;

  a {
    color: inherit;
  }
}

.NotificationDismiss {
  margin-left: spacing(1);

  @include mq($breakpoint-tablet) {
    margin-left: spacing(2);
  }
}

.shadow {
  box-shadow: 0 4px 10px 0 rgba($color-coolgrey-l60, 0.5);
}

@each $key, $value in $color-status-list {
  .#{$key} {
    background-color: tint($value, 80%);
    border-left-color: $value;
    color: shade($value, 75%);

    svg {
      fill: $value !important;
    }
  }
}
