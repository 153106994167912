
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
$gap-list: (
  's': 0.5,
  'm': 1,
  'l': 2
);

.Inline {
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.shrink {
  display: inline-flex;
}

.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-spaceAround {
  justify-content: space-around;
}
.justify-spaceBetween {
  justify-content: space-between;
}

.s {
  margin: spacing(0.5 * -1);
  & > * {
    margin: spacing(0.5);
  }
}

.m {
  margin: spacing(1 * -1);
  & > * {
    margin: spacing(1);
  }
}

.l {
  margin: spacing(2 * -1);
  & > * {
    margin: spacing(2);
  }
}

.xl {
  margin: spacing(2 * -1);
  & > * {
    margin: spacing(2);
  }

  @include mq($breakpoint-tablet) {
    margin: spacing(3 * -1);
    & > * {
      margin: spacing(3);
    }
  }
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   gaps: map-keys($gap-list);
// }
